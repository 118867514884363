import * as Sentry from '@sentry/vue';

export const initializeSentry = (app, envVariables, router) => {
  if (['staging', 'live', 'production'].includes(envVariables.MODE)) {
    Sentry.init({
      app,
      environment: envVariables.VITE_ENVIRONMENT,
      dsn: envVariables.VITE_SENTRY_DSN,
      release: envVariables.VITE_RELEASE_TAG,
      tracesSampleRate: 0.1,
      integrations: [Sentry.browserTracingIntegration({ router })],
    });
  }
};

export const sentryVitePluginConfig = (envVariables, project) => {
  return {
    org: envVariables.VITE_SENTRY_ORG,
    project: project,
    authToken: envVariables.VITE_SENTRY_AUTH_TOKEN,
    release: {
      name: envVariables.VITE_RELEASE_TAG,
    },
    telemetry: false,
  };
};
